 

export const USER_SET_AUTHENTICATE = 'USER_SET_AUTHENTICATE'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_SET_INFO = 'USER_SET_INFO'
export const USER_SET_ORGAN = 'USER_SET_ORGAN'
export const USER_SET_SHIPPER = 'USER_SET_SHIPPER'

export const USER_SET_PARK_USER_TYPE = 'USER_SET_PARK_USER_TYPE'
export const USER_SET_PARK_INFO = 'USER_SET_PARK_INFO'
export const USER_SET_PARK_CUSTOMER_INFO = 'USER_SET_PARK_CUSTOMER_INFO'
export const USER_SET_PARK_CARRIER_INFO = 'USER_SET_PARK_CARRIER_INFO'

export const USER_SET_TENANTINFO = 'USER_SET_TENANTINFO'
export const USER_SET_SCHEMAINFO = 'USER_SET_SCHEMAINFO'
export const USER_SET_SYSCONFIG = 'USER_SET_SYSCONFIG'
export const USER_SET_EMPLOYER = 'USER_SET_EMPLOYER'
export const USER_SET_EMPLOYER_LIST = 'USER_SET_EMPLOYER_LIST'
export const USER_SET_TEAM = 'USER_SET_TEAM'
export const USER_SET_AVATAR = 'USER_SET_AVATAR'
export const USER_SET_MENU = 'USER_SET_MENU'
export const USER_MENU_ACTIVE_ONE = 'USER_MENU_ACTIVE_ONE'
export const USER_MENU_ACTIVE = 'USER_MENU_ACTIVE'

export const PAGE_LIST = 'PAGE_LIST'
export const PAGE_ADD = 'PAGE_ADD'
export const PAGE_REMOVE = 'PAGE_REMOVE'
export const PAGE_CLEAR = 'PAGE_CLEAR'
export const PAGE_CLOSE_OTA = 'PAGE_CLOSE_OTA'
export const PAGE_CLOSE_CURRENT = 'PAGE_CLOSE_CURRENT'


export const SET_CACHE = 'SET_CACHE'

export const MENU_SET_COLLAPSE ='MENU_SET_COLLAPSE'
export const APP_SCROLL_TOP ='APP_SCROLL_TOP'


export const MSG_SEND ='MSG_SEND'
export const MSG_READ ='MSG_READ'

export const SYS_CONFIG = "SYS_CONFIG"
export const ORDER_REC_CONFIG = "ORDER_REC_CONFIG"
export const USER_CUSTS = "USER_CUSTS"
export const USER_ORGANS = "USER_ORGANS"


export const CSRF_TOKEN = "CSRF_TOKEN"