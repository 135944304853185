<template>
  <div>
    <el-dialog :visible.sync="visible" append-to-body :modal="false" :show-close="false"  width="240px" top="0">
      <div class="ass_user_info" >
        <div class="ass-img">
          <img :src="avatarUrl" />
          <div class="ass-name">
            <span style="font-size:13px;color:#fff">{{name}}</span>
            <span v-show="organName != null" style="margin-top:5px;font-size:11px;color:#90959F">当前机构：{{organName}}</span>
          </div>
        </div>
        <div class="ass-sp" v-show="options.length>0">
          <span  style="">代运营货主：{{showShipperName}} </span>
          <el-dropdown trigger="click" slot="append" @command="handleCommand">
            <span class="el-dropdown-link">
                切换<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="project-dropdown">
              <el-input v-model="search" class="opt-search" prefix-icon="el-icon-search" placeholder="请输入货主名称" clearable/>
              <el-dropdown-item v-for="item in searchOption" :key="item.id" :command="item.id"
                                :disabled="shipper && item.id===shipper.id">
                {{item.name}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="ass_quick_entry">
          <el-row>
            <el-col :span="12">
              <a :href="securityUrl">
                <i class="el-icon-setting"></i> 安全设置
              </a>
            </el-col>
            <el-col :span="12">
              <a @click="signOut">
                <i class="icon gs-icon shutdown"></i> 退出登录
              </a>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import aEntityAPI from "@/api/aEntityAPI";
import baseApi from "@/api/sysAPI";
import {mapActions, mapGetters} from "vuex";
import Vue from 'vue'

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: () => false
    },
    userinfo: Object,
    avatarUrl: String,
    singOutFn: Function
  },
  data() {
    return {
      select: null,
      options: [],
      search: '',
      name:null,
      organName:null,
      securityUrl:  "common.html#/securityConfig",
      msgUrl: window.location.pathname + "#/msgList"
    };
  },
  computed: {
    ...mapGetters({
      shipper: "user/shipper",
      __organ: "user/organ",
    }),
    searchOption() {
      if (!this.search) {
        return this.options
      }else{
        return this.options.filter(item =>
            item.name.indexOf(this.search) > -1 || (item.projectName && item.projectName.indexOf(this.search) > -1)
        )
      }

    },
    showShipperName(){
      if(this.shipper){
        return this.shipper.name;
      }else{
        return ""
        // //客服登录，无货主信息，共发展业务默认显示第一个，否则，功能模块查询报错
        // if(this.options.length > 0){
        //   this["user/refreshShipper"](this.options[0].id).then((data) => {
        //     if (data) {
        //       this.$message.success("切换身份成功");
        //     }
        //   });
        //   // return this.options[0].name;
        // }else {
        //   return "";
        // }
      }
    },
    visible: {
      get() {
        return this.showDialog
      },
      set(v) {
        this.$emit('update:showDialog', v)
      }
    }
  },
  mounted() {
    this.name = (this.userinfo.organ?this.userinfo.organ.name:'')+ '-' + this.userinfo.realName
    this.organName = this.__organ?this.__organ.name:null;
    // this.listShipperPartner()
    this.handleTeantZbrl()
  },

  methods: {
    ...mapActions(["user/refreshShipper"]),
    signOut() {
      this.singOutFn();
    },

    handleTeantZbrl() {
      var host = window.location.host;
      if (host.indexOf(":") > -1) {
        host = host.split(":")[0];
      }
      console.log("===========>" + host)
      let params = {
        start: this.grid_pageSize * (this.grid_pageNo - 1),
        limit: this.grid_pageSize,
        cls: "TenantInfo",
        fields: [],
        q: {
          domain__like: host,
        },
      };
      this.grid_loading = true;


      return Vue.http.post("api/TenantInfoService/findPage",
        { cmd: 'tenant/tenant.pageTenantProduct4Count', qp: JSON.stringify(params) }
      ).then(response => {
        this.grid_loading = false;
        if (response.data.data) {
          var data = response.data.data;
          console.log(data)
          let manageFlag = false;
          if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
              if (data[i].product == '人力系统' && data[i].status != '已停用') {
                manageFlag = true;
              }
            }
          }
          if(!manageFlag){
            this.listShipperPartner()
          }
        } else {
          this.$message.error("查询会员列表失败");
        }
      });

      // baseApi.pageMapByCmd("TenantInfo", "tenant/tenant.pageTenantProduct", params).then((response) => {
      //     this.grid_loading = false;
      //     if (response.data.data) {
      //       var data = response.data.data;
      //       console.log(data)
      //         // this.projectList = response.data.data;
      //         // this.grid_total = response.data.total;
      //     } else {
      //         this.$message.error("查询数据失败");
      //     }
      // });
    },
    listShipperPartner() {
      let params = {
        start: 0,
        limit: 100,
        q: {

        }
      }
      aEntityAPI.request("GylOperationConsoleService", "listShipper", {qp: JSON.stringify(params)}).then(response => {
        if (response.data.success) {
          if(response.data.data){
            this.options = response.data.data;
          }else {
            this.options =[]
          }
        }
      });
    },
    handleCommand(command) {
      //同步 跟心 后台 currentrShipper,currentOrgan ,与前端 vuex 的 user/shipper
      if (command) {
        this["user/refreshShipper"](command).then((data) => {
          if (data) {
            this.$message.success("切换身份成功");
            //重载页面
            window.location.reload()
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog{
  position: absolute;
  right: 0;
}

/deep/ .el-dialog__header{
  display: none !important;
}
/deep/ .el-dialog__body{
  padding: 0px;
}

.ass_user_info {
  position: absolute;
  display: inline-block;
  width: 240px;
  background: #333333;
  right: 0;
}

.ass-img {
  min-height: 50px;
  // line-height: 50px;
  border-bottom: 1px solid #c0c4cc;
  padding: 10px 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.ass-sp {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #c0c4cc;
  padding: 5px 10px;
  overflow: hidden;
  color: #fff;
  display:flex;
}
.ass-img img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border-radius: 50%;
}

.ass-name {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.ass_quick_entry a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.ass_quick_entry a > i {
  font-size: 14px;
}

.ass_quick_entry .el-row {
  padding: 10px;
}

.ass_quick_entry .el-col {
  text-align: center;
}

.opt-drop {
  padding: 5px
}

/* .opt-search{

} */

.project-dropdown{
  height:300px;
  padding: 5px;
  overflow: auto;
  min-width:250px;
  float:right;
}
.project-dropdown::-webkit-scrollbar
{
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;
}
.project-dropdown::-webkit-scrollbar-track
{
  border-radius: 10px;
  background-color: #F5F5F5;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 10px;
}
</style>