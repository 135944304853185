<template>
  <div class="toolBar">
    <div class="rightBottomPanel">
      <div class="item" v-for="(item,index) in items" :key="index" @click="handleClick(index)">
        <img class="icon" :src="item.icon" />
        <div class="label">{{item.label}}</div>
      </div>
    </div>
    <ai ref="ai"></ai>
  </div>
</template>
<script>
import ai from '@/components/toolBar/ai'
export default {
  components: {
    ai
  },
  data() {
    return {
      items: [
        {
          ref: "ai",
          icon: require("@/assets/toolbar/ai.png"),
          label: "数字调度员",
        }
      ],
    };
  },
  methods: {
    handleClick(index) {
      this.$refs[this.items[index].ref].open()
    }
  },
};
</script>
<style lang="less" scoped>
.toolBar .rightBottomPanel {
  position: fixed;
  bottom: 50px;
  right: 0;
  width: 80px;
  background-color: #eff8fc;
  z-index: 999999;
}

.toolBar .rightBottomPanel .item {
  text-align: center;
  height: 50px;
  padding: 5px 0;
  cursor: pointer;
}

.toolBar .rightBottomPanel .item:hover {
  background-color: #deebfd;
}

.toolBar .rightBottomPanel .item .icon {
  width: 28px;
  height: 28px;
}

.toolBar .rightBottomPanel .item .label {
  color: #747d88;
  font-size: 11px;
  font-weight: 600;
}
</style>